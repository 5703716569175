import React, { useState, useEffect,  useReducer } from "react";
import {
  Grid,
  Paper,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Table,
  TableRow,
  TableCell,
  FormHelperText,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from "@mui/material/";
import axios from "axios"
import ModalBox from './components/modal'
import TableComponent from "./components/table";
import { useForm, Controller } from "react-hook-form";
import Cards from "./components/cards";
import TypingAnimation from "./components/typingAnimation";
import extractiveData from "./data/extractive.json"
import generativeData from "./data/generative.json"
//import {postQuerySearch} from './service/services'

export default function Search() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const EXTRACTIVE = "extractive";
  const GENERATIVE = "generative";

 
  const [state, setState] = useState({
    sourceData: extractiveData,
    data: [],
    question: "",
    showCards: false,
    showSearchAnswer: false,
    showSearchQuestion: false,
    filterOption: "extractive",
    extractiveView: false,
    generativeView: false,
    dropDownValue: 'navsea',
    hideTyping: false,
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
    loading:  false
  })

  const initForm = {
    data: [],
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2
  }

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  );

  
  const handleQuestionChange = (event) => {
    console.log(event.target.value);

      localStorage.setItem("question", event.target.value);
    setState({
      question: event.target.value,
    });
  };

  const handleIndexChange = (event) => {
    console.log(event.target.value);

    localStorage.setItem("index", event.target.value);
    setState({
      dropDownValue: event.target.value,
    })
  }

   const postQuerySearch =  (data) => {
    console.log("Post Body", data.index)
    let index 
    if(data.index){
      index = data.index
    }

    else {
      index = 'navsea'
    }

    console.log("Index",  index)
  
     axios.post('https://idmgpu.saicds.com/query', {

      query: data.questionSearch,
      mode: data.mode,
      count: 5,
      index: state.dropDownValue,
      score_filter: 0.1,
      retriever:  "dpr",
      reader: "albert",
      generator: "seq2seq"

  
  }).then(response => {
   
    if (data.mode === EXTRACTIVE) {
      // Extractive View
      console.log("Extractive");
      setState({
        extractiveView: true,
        showCards: true,
        showSearchQuestion: true,
        showSearchAnswer: true,
        filterOption: 'extractive',
        dropDownValue:  state.dropDownValue 
       
      })
      }

        if (data.mode === GENERATIVE) {
        // Generative View
        console.log("Generative");
        setState({
            generativeView: true,
            showCards: true,
            showSearchQuestion: true,
            showSearchAnswer: true,
            filterOption: 'generative',
            dropDownValue:  state.dropDownValue 
        })
        }

    console.log(response.data)

    localStorage.setItem("getData",  JSON.stringify(response.data))
     
  })
  .catch(error => {
      console.log(error)
  })
  
  }

  const searchHandler = (data) => {
    console.log(data)

    setState({
      hideTyping: true,
      loading: true
    })

        postQuerySearch(data)
      
  }    

        const registerOptions = {
            searchInput: {
            required: "Please enter a valid query",
            minLength: {
                value: 3,
                message: "Question must be at least 3 characters in length"
            }
            }
        }

        const questions = [
            { title: 'What is impacting Iranian Diplomacy?' },
            { title: 'Who used russian weapons?' }
           
          ]

    
    return (
        <div className="searchContainer">
        <Grid container xs={12} >

                <div className="spacer" />
                <div className="spacer" />
                <Grid item xs={2} />
                <Grid item xs={8} className="marginCenter">
                    
                    <FormHelperText
                      className="floating"
                    >
                      {errors?.questionSearch ? <span  className="validationBubble bottom" >{errors.questionSearch.message}</span> : null}
                    </FormHelperText>
                    
                </Grid>
          
              <Grid item xs={2} />

             <div className="main" >
                  
                <div class="search-box">

                    <Grid item xs={6} sm={6} lg={6} >

                    <Autocomplete 
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={questions.map((option) => option.title)}
                   
                     renderInput={(params) => (
                      
            
                        <TextField 
                            className="input" 
                            sx={{
                                "& fieldset": { border: 'none' },
                            }}
                            
                            autoFocus
                            placeholder="Search" 
                            {...params}
                            id="input" 
                            name="questionSearch" 
                            
                            onChange={handleQuestionChange}  
                            disableUnderline  
                            style={{border: 'transparent'}}
                            {...register("questionSearch", registerOptions.searchInput )}
                        
                    
                            />

                            
                            )}

                    />

                    </Grid>

                    <Grid xs={2} sm={2} lg={2} >
                      
                         <FormControlLabel
                            style={{height: '30px'}} 
                            className="dropDown"
                            sx={{ m: 1, minWidth: 120, maxWidth: 200 }} 
                              control={
                                <Controller
                                  control={control}
                                  name="index"
                                  required={true}
                                  
                                  {...register("index")}
                                  render={({ field: { onChange } }) => (
                                    <FormControl fullWidth style={{marginTop: '-8px', border: '0'}} >
                                    {/* <InputLabel  value="navsea" id="demo-simple-select-label">Index</InputLabel> */}
                                    <Select
                                    
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className="select"
                                        value={state.dropDownValue === '' ? 'navsea' : state.dropDownValue }
                                        onChange={handleIndexChange}
                                      >
                                        <MenuItem value="navsea">NAVSEA</MenuItem>
                                        <MenuItem value="cnn-small">Ukarine/Russia</MenuItem>
                                      </Select> 
                                      </FormControl>
                                  )}
                                />
                              }
                            />
                    </Grid>

                    <Grid item xs={4} sm={4} lg={4} >

                    <FormControlLabel
                    style={{height: '30px'}} 
                    className="dropDown"
                    sx={{ m: 1, minWidth: 120, maxWidth: 300 }} 
                      control={
                        <Controller
                          control={control}
                          name="mode"
                          required={true}
                          {...register("mode")}
                          render={({ field: { onChange } }) => (
                            <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                    
                            // onChange={handleOptionChange}
                            // value={state.filterOption}
                            onChange={(e) => onChange(e.target.value)}
                      
                        >
                            <FormControlLabel value="extractive"  control={<Radio style={{color: '#b6c743'}} />} label="Extractive" />
                            <FormControlLabel value="generative" control={<Radio style={{color: '#b6c743'}} />} label="Generative" />
                    
                        </RadioGroup>
                          )}
                        />
                      }
                    />

                </Grid>

                <Grid item xs={1} md={1} lg={1} style={{float: 'right', alignItems: 'right'}} >
                 
                  <button type="submit"  onClick={handleSubmit(searchHandler)}  id="submitBtn" ><i class="search-icon"></i></button>

                </Grid>

            
              </div>

            </div>
     
   
        <Grid container xs={12}>
          <Grid item xs={12} className="marginCenter">
            {state.hideTyping === false ? <TypingAnimation /> : ""}
          </Grid>
        </Grid>

        
        <Grid item xs={5} />
        <Grid item xs={2} className="marginCenter" >
         {state.loading === true ?
         <div class="loadingContainer">
            <div class="loading">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
        
            </div>
          </div>
          : ''}
        </Grid>
        <Grid item xs={5} />
    
        {state.generativeView === true ? (
          <Grid container xs={12}  className="fadePanel marginCenter" >
        
            <Grid container xs={12} >

          
              {state.showSearchAnswer === true ? (

                
                <Grid item xs={8} className="marginCenter">
      
                  <Typography
                    component={Paper}
                    elevation={3}
                    style={{
                      color: "#016bb5",
                      borderRadius: "12px",
                      fontSize: "18px",
                      textAlign: "left",
                      padding: "60px 50px 40px 50px"
                    }}
                  >
                     <span className="typingContainer">
                      <div class="css-typing">
                        <p>Answer:</p>{" "}
                        <br/>
                        <br/>
                      </div>
                    </span>

                    
                      <span>
                    
                        {JSON.parse(localStorage.getItem("getData"))[0].Answer}
                        
                      </span>
                  
       

                    
                  </Typography>
                  <div className="spacer" />
                  <div className="spacer" />
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            
            {JSON.parse(localStorage.getItem("getData"))[0].Sources.map((data) => (
                
            <Grid item xs={3} style={{padding: '15px'}}  className="marginCenter" >

              <Cards
                context={data.Context}
                name={data.Name}
                score={data.Score}
              /> 

               
              </Grid>
            ))}

            
            <Grid xs={10} className="marginCenter" style={{alignItems: 'center', textAlign: 'center'}} >
                 <br/>
                <div className="spacer" />
                <div className="dividerShape" />
                <div className="spacer" />
                <div className="spacer" />
                <ModalBox
                    label={"Add Files to FOIA Case"}
                />

                <br/>
             </Grid>


          </Grid>
        ) : (
          ""
        )}

        {state.extractiveView === true ? (
          <Grid container xs={12} spacing={3} className="fadePanel">
      
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <TableComponent data={JSON.parse(localStorage.getItem("getData"))} />
                  </TableCell>
                </TableRow>
              </Table>
            </Grid>

            <Grid xs={12}  sm={12} md={12} lg={12} className="marginCenter" style={{alignItems: 'center', textAlign: 'center'}} >
                <br/>
                <div className="spacer" />
                <div className="dividerShape" />
                <div className="spacer" />
            
                <ModalBox
                    label={"Add Files to FOIA Case"}
                />

             
            </Grid>

          </Grid>
        ) : (
          ""
        )}
      </Grid>

   
         <div className='spacer' />
          <div className='spacer' />
          <div className='spacer' />
    </div>
  )
}

// import React, { useState, useEffect,  useReducer } from "react";
// import {
//   Grid,
//   FormControl,
//   Paper,
//   TextField,
//   Radio,
//   RadioGroup,
//   FormControlLabel,
//   Typography,
//   Table,
//   TableRow,
//   TableCell,
//   FormHelperText,
//   Autocomplete,
//   Button,
//   Card,
//   CardContent
// } from "@mui/material/";
// import axios from "axios"
// import ModalBox from './components/modal'
// import TableComponent from "./components/table";
// import { useForm, Controller } from "react-hook-form";
// import Cards from "./components/cards";
// import TypingAnimation from "./components/typingAnimation";
// import extractiveData from "./data/extractive.json"
// import generativeData from "./data/generative.json"
// //import {postQuerySearch} from './service/services'

// export default function Search() {
//   const {
//     register,
//     handleSubmit,
//     control,
//     formState: { errors },
//   } = useForm();

//   const EXTRACTIVE = "extractive";
//   const GENERATIVE = "generative";

 
//   const [state, setState] = useState({
//     sourceData: extractiveData,
//     data: [],
//     question: "",
//     showCards: false,
//     showSearchAnswer: false,
//     showSearchQuestion: false,
//     filterOption: "extractive",
//     extractiveView: false,
//     generativeView: false,
//     hideTyping: false,
//     query_text: "",
//     mode: "",
//     count: "",
//     index: "",
//     score_filter: 0.2,
//     loading:  false
//   })

//   const initForm = {
//     data: [],
//     query_text: "",
//     mode: "",
//     count: "",
//     index: "",
//     score_filter: 0.2
//   }

//   const [formVal, dispatch] = useReducer(
//     (curVal, newVal) => ({ ...curVal, ...newVal }),
//     initForm
//   );

  
//   const handleQuestionChange = (event) => {
//     console.log(event.target.value);

//       localStorage.setItem("question", event.target.value);
//     setState({
//       question: event.target.value,
//     });
//   };

//   const handleOptionChange = (event) => {
//     console.log(event.target.value);

//     localStorage.setItem("option", event.target.value);
//     setState({
//       filterOption: event.target.value,
//     });
//   }

//    const postQuerySearch =  (data) => {
//     console.log("Post Body", data)
        
//     if (data.mode === EXTRACTIVE) {
//       // Extractive View
//       console.log("Extractive");

//         setTimeout(() => {
//           setState({
//             extractiveView: true,
//             showCards: true,
//             showSearchQuestion: true,
//             showSearchAnswer: true,
//             filterOption: 'extractive',
          
//           })
//         }, 2000)

     
//       }

//         if (data.mode === GENERATIVE) {
//         // Generative View
//         console.log("Generative");
//         setTimeout(() => {
//             setState({
//                 generativeView: true,
//                 showCards: true,
//                 showSearchQuestion: true,
//                 showSearchAnswer: true,
//                 filterOption: 'generative'
//             })
//          }, 2000)

//         }

  
//   }

//   const searchHandler = (data) => {
//     console.log(data)

//     setState({
//       hideTyping: true,
//       loading: true
//     })

//         postQuerySearch(data)
      
//   }    

//         const registerOptions = {
//             searchInput: {
//             required: "Please enter a valid query",
//             minLength: {
//                 value: 3,
//                 message: "Question must be at least 3 characters in length"
//             }
//             }
//         }

//         const questions = [
//             { title: 'Will US provide long range missiles to Ukraine?' },
//             { title: 'Who used russian weapons?' }
           
//           ]

    
//     return (
//         <div className="searchContainer">
//         <Grid container xs={12} >

//                 <div className="spacer" />
//                 <div className="spacer" />
//                 <Grid item xs={2} />
//                 <Grid item xs={8} className="marginCenter">
                    
//                     <FormHelperText
//                       className="floating"
//                     >
//                       {errors?.questionSearch ? <span  className="validationBubble bottom" >{errors.questionSearch.message}</span> : null}
//                     </FormHelperText>
                    
//                 </Grid>
          
//               <Grid item xs={2} />

//              <div className="main" >
                  
//                 <div class="search-box">

//                     <Grid item xs={7} sm={7} lg={7} >

//                     <Autocomplete 
//                     freeSolo
//                     id="free-solo-2-demo"
//                     disableClearable
//                     options={questions.map((option) => option.title)}
                   
//                      renderInput={(params) => (
                      
            
//                         <TextField 
//                             className="input" 
//                             sx={{
//                                 "& fieldset": { border: 'none' },
//                             }}
                            
//                             autoFocus
//                             placeholder="Search" 
//                             {...params}
//                             id="input" 
//                             name="questionSearch" 
                            
//                             onChange={handleQuestionChange}  
//                             disableUnderline  
//                             style={{border: 'transparent'}}
//                             {...register("questionSearch", registerOptions.searchInput )}
                        
                    
//                             />

                            
//                             )}

//                     />

//                     </Grid>

//                     <Grid item xs={5} sm={4} lg={4} >

//                     <FormControlLabel
//                     style={{height: '30px'}} 
//                     className="dropDown"
//                     sx={{ m: 1, minWidth: 120, maxWidth: 300 }} 
//                       control={
//                         <Controller
//                           control={control}
//                           name="mode"
//                           required={true}
//                           {...register("mode")}
//                           render={({ field: { onChange } }) => (
//                             <RadioGroup
//                             row
//                             aria-labelledby="demo-row-radio-buttons-group-label"
                    
//                             // onChange={handleOptionChange}
//                             // value={state.filterOption}
//                             onChange={(e) => onChange(e.target.value)}
                      
//                         >
//                             <FormControlLabel value="extractive"  control={<Radio style={{color: '#b6c743'}} />} label="Extractive" />
//                             <FormControlLabel value="generative" control={<Radio style={{color: '#b6c743'}} />} label="Generative" />
                    
//                         </RadioGroup>
//                           )}
//                         />
//                       }
//                     />

          
//                       {/* <FormControl sx={{ m: 1, minWidth: 120, maxWidth: 300 }}   style={{height: '30px'}} className="dropDown">

//                           <RadioGroup
//                               row
//                               aria-labelledby="demo-row-radio-buttons-group-label"
//                               name="mode"
//                               onChange={handleOptionChange}
//                               value={state.filterOption}
//                               {...register("mode" )}
//                           >
//                               <FormControlLabel value="extractive"  control={<Radio style={{color: '#b6c743'}} />} label="Extractive" />
//                               <FormControlLabel value="generative" control={<Radio style={{color: '#b6c743'}} />} label="Generative" />
                      
//                           </RadioGroup>
//                       </FormControl> */}
//                 </Grid>

//                 <Grid item xs={1} md={1} lg={1} style={{float: 'right', alignItems: 'right'}} >
                 
//                   <button type="submit"  onClick={handleSubmit(searchHandler)}  id="submitBtn" ><i class="search-icon"></i></button>

//                 </Grid>

            
//               </div>

//             </div>
     
   
//         <Grid container xs={12}>
//           <Grid item xs={12} className="marginCenter">
//             {state.hideTyping === false ? <TypingAnimation /> : ""}
//           </Grid>
//         </Grid>

        
//         <Grid item xs={5} />
//         <Grid item xs={2} className="marginCenter" >
//          {state.loading === true ?
//          <div class="loadingContainer">
//             <div class="loading">
//               <span></span>
//               <span></span>
//               <span></span>
//               <span></span>
//               <span></span>
//               <span></span>
//               <span></span>
        
//             </div>
//           </div>
//           : ''}
//         </Grid>
//         <Grid item xs={5} />
    

//         {state.generativeView === true ? (
//           <Grid container xs={12}  className="fadePanel marginCenter" >
        
//             <Grid container xs={12} >

          
//               {state.showSearchAnswer === true ? (

                
//                 <Grid item xs={8} className="marginCenter">
      
//                   <Typography
//                     component={Paper}
//                     elevation={3}
//                     style={{
//                       color: "#016bb5",
//                       borderRadius: "12px",
//                       fontSize: "18px",
//                       textAlign: "left",
//                       padding: "60px 50px 40px 50px"
//                     }}
//                   >
//                      <span className="typingContainer">
//                       <div class="css-typing">
//                         <p>Answer:</p>{" "}
//                         <br/>
//                         <br/>
//                       </div>
//                     </span>

                    
//                       <span>
                    
//                         {generativeData.Answer}
//                       </span>
                  
       

                    
//                   </Typography>
//                   <div className="spacer" />
//                   <div className="spacer" />
//                 </Grid>
//               ) : (
//                 ""
//               )}
//             </Grid>

            

  
//             {generativeData.Sources.map((data) => (
                
//             <Grid item xs={3} style={{padding: '15px'}}  className="marginCenter" >

//               <Cards
//                 number={data.Number}
//                 context={data.Context}
//                 name={data.Name}
//                 score={data.Score}
//               /> 

               
//               </Grid>
//             ))}

//               <div className="spacer" />
//               <div className="spacer" />
//               <div className="dividerShape2" />
            
//             <Grid xs={4} className="marginCenter" style={{marginTop: '100px',alignItems: 'center', textAlign: 'center'}} >
//                  <br/>
                
            
//                 <ModalBox
//                     label={"Add Files to FOIA Case"}
//                 />

//                 <br/>
//              </Grid>


//           </Grid>
//         ) : (
//           ""
//         )}

//         {state.extractiveView === true ? (
//           <Grid container xs={12} spacing={3} className="fadePanel">
      
//             <Grid item xs={12} sm={12} md={12} lg={12}>
//               <Table>
//                 <TableRow>
//                   <TableCell>
//                     <TableComponent data={extractiveData} />
//                   </TableCell>
//                 </TableRow>
//               </Table>
//             </Grid>

//             <Grid xs={12}  sm={12} md={12} lg={12} className="marginCenter" style={{alignItems: 'center', textAlign: 'center'}} >
//                 <br/>
//                 <div className="spacer" />
            
//                 <ModalBox
//                     label={"Add Files to FOIA Case"}
//                 />

             
//             </Grid>

//           </Grid>
//         ) : (
//           ""
//         )}
//       </Grid>

   
//          <div className='spacer' />
//           <div className='spacer' />
//           <div className='spacer' />
//     </div>
//   )
// }

