import React from "react"
import { Grid } from "@mui/material"
import SaicLogo from '../assets/images/Saic-Logo-White.png'

const Header = () => {
  return (
    <div>
      <Grid container xs={12} sm={12} md={12} lg={12} >
        <Grid item xs={2} sm={2} md={2} lg={2} >
            <a href="/"><img src={SaicLogo}  alt="Saic Logo" /></a>
           
        </Grid>

        <Grid xs={10}>
        <div className="dividerShape2" />
        </Grid>
      </Grid>
    </div>
  )
}

export default Header
