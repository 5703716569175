import * as React from 'react'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography
} from '@mui/material'
import PDF_WHITE from '../assets/images/PDF-Icon-white.png'

export default function SearchCard(props) {

const card = (
  <React.Fragment>
    <div class="card">
      <div class="content">
        <div class="front">
        <CardContent>

          <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{float: 'right', opacity: '0.4'}} >
              <b>{props.number}</b>
          </Typography> 
          <br/>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <Typography>Score:  </Typography><span className="score" >{props.score}</span>
        </Typography>  

        <Typography sx={{ mb: 1.5 }} color="text.secondary">
     
        <br/>
        <Typography>Source file:</Typography>
       
        <small className="file" >{props.name}</small>&nbsp;&nbsp;&nbsp;&nbsp;
        <img src={PDF_WHITE} className="fileType" style={{width: '25px'}} />
        
        </Typography>
        <br/>

        <Typography sx={{ mb: 1.5 }} color="text.secondary" className="capitalize desc" style={{height:'100px'}}>
            {props.context}
        </Typography>  

          </CardContent>
          
        </div>

          {/* <div className="back" >
              <img src={PDF_WHITE} style={{height: '75px'}} />
            <br/>
            <Typography  style={{color: '#fff'}} >Click to download source material</Typography>
          </div> */}
      </div>
      </div>

  </React.Fragment>
 
)

  return (

      <Box >
       
            <Card variant="outlined" className="cardComponent" >{card}</Card>
  
      </Box>

  )
}



